import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from '../../assets/images/premium.png';
import axios from '../../utils/axios';

const Premium = ({ onClose }) => {
  let result = JSON.parse(localStorage.getItem('@userInfo'));
  const [isLoading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [url, setUrl] = useState(null);
  let inputRef = useRef(null);

  console.log('item: ', item)

  const onPurchase = () => {
    if (item) {
      setLoading(true);
      axios
        .post('/transactions/pay', { user: result.id, package: item })
        .then((res) => {
          setUrl(res.data.url);
          setLoading(false);
          setTimeout(() => {
            inputRef.current.click();
            return onClose();
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <OverLay onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <img className='logo' src={`${Icon}`} alt='MeDrugApp' />
        <h1 className='title'>خرید بسته ویژه</h1>
        <div className={`row ${item === 2 ? 'active' : ''}`} onClick={() => setItem(2)}>
          <span className='name'>۱ ساله</span>
          <span className='price'>۵۰ هزار تومان</span>
        </div>
        <div className={`row ${item === 1 ? 'active' : ''}`} onClick={() => setItem(1)}>
          <span className='name'>۶ ماهه</span>
          <span className='price'>۳۰ هزار تومان</span>
        </div>
        {result && <p className='description'>برای مشاهده کامل محتوا برنامه باید بسته ویژه را خریداری نمایید.</p>}
        {!result && <p className='description'>برای نهایی کردن خرید باید ابتدا وارد حساب کاربری خود شوید.</p>}
        {result ? (
          <button onClick={onPurchase} className='btn'>
            {isLoading && (
              <div className='lds-ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {!isLoading && item ? 'خرید بسته' : 'انتخاب بسته'}
          </button>
        ) : (
          <Link to='/login' className='btn'>
            ورود به حساب کاربری
          </Link>
        )}
        {isLoading && <p>در حال انقال به درگاه بانکی...</p>}
        <a ref={inputRef} style={{ display: 'none' }} href={url} rel='noopener'></a>
      </Container>
    </OverLay>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  display: flex;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 40;
  background-color: #fff;
  transition: background-color 480ms ease-in-out;
  transform: translate(0, -50%);
  .close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: #fff;
      }
    }
  }
  .logo {
    height: 80px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 4px;
    border: 2px solid #231e5b;
    width: 80%;
    margin-bottom: 15px;
    padding: 10px 0;
  }
  .row.active {
    background-color: #231e5b;
  }
  .name {
    font-size: 1.5em;
    font-weight: bold;
    color: #231e5b;
    margin-bottom: 15px;
  }
  .row.active .name {
    color: #fff;
  }
  .price {
    font-size: 1.2em;
    color: #231e5b;
  }
  .row.active .price {
    color: #fff;
  }
  .line-through {
    text-decoration: line-through;
    opacity: 0.8;
    margin-left: 8px;
  }
  .title {
    font-size: 1.6em;
    font-weight: bold;
    color: #231e5b;
    margin-bottom: 15px;
    transition: color 480ms ease-in-out;
  }
  .description {
    font-size: 16px;
    color: #616d85;
    margin: 0 0 10px 0;
    transition: color 480ms ease-in-out;
    text-align: center;
  }
  .btn {
    border: 0;
    border-radius: 4px;
    background-color: #231e5b;
    color: #fff;
    font-size: 18px;
    font-family: 'dana';
    padding: 5px 15px;
    margin-top: 20px;
    text-decoration: none;
  }
  .lds-ring {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const OverLay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`;

export default Premium;
