import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

// Service
import Sneeze from '../../assets/images/sneeze.png';
import Milk from '../../assets/images/milk.png';
import Medicine from '../../assets/images/medicine.png';
import Pills from '../../assets/images/pills.png';
import Baby from '../../assets/images/baby.png';
import Alert from './Alert';

const items = [
  {
    _id: '5ffd99fea9019d2c74406643',
    title: 'داروها',
    icon: Medicine,
    child: [],
  },
  {
    _id: '5ffc75d58b2972229de26b0f',
    title: 'داروهای گیاهی',
    icon: Milk,
    child: [
      {
        _id: '5ffc799c8b2972229de26b10',
        title: 'خواب آور کودکان',
        icon: Baby,
      },
      {
        _id: '5ffc75668b2972229de26b0e',
        title: 'سرفه و سرماخوردگی',
        icon: Sneeze,
      },
    ],
  },
  {
    _id: '5ffc79ab8b2972229de26b11',
    title: 'سایر داروها',
    icon: Pills,
    child: [],
  },
];

const Categories = memo(({ defaultItems }) => {
  const [showAlert, setShowAlert] = useState(true);

  const closeAlert = () => setShowAlert(false);

  return (
    <Container>
      {showAlert ? (
        <div style={{marginBottom: '2rem', width: '100%'}}>
          <Alert full close={closeAlert} />
        </div>
      ) : (
        <AnimatePresence>
          {defaultItems
            ? defaultItems.map((item) => {
                return (
                  <motion.div key={item._id} className='items' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Link to={`/products/${item._id}?title=${item.title}`}>
                      <div className='icon' style={{ backgroundImage: `url(${item.icon})` }}></div>
                      <span className='name'>{item.title}</span>
                    </Link>
                  </motion.div>
                );
              })
            : items.map((item) => {
                return (
                  <motion.div key={item._id} className='items' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Link to={item.child.length > 0 ? `/categories/${item._id}` : `/products/${item._id}?title=${item.title}`}>
                      <div className='icon' style={{ backgroundImage: `url(${item.icon})` }}></div>
                      <span className='name'>{item.title}</span>
                    </Link>
                  </motion.div>
                );
              })}
        </AnimatePresence>
      )}
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 20px;
  z-index: 20;
  width: calc(100% - 40px);
  .items {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 20px 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0 rgb(206 206 206 / 20%);
    a,
    .link {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
    }
    .icon {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
    }
    .name {
      font-size: 1.2em;
      color: #231e5b;
    }
    .alert-container {
      width: full;
      margin-bottom: 8px;
    }
  }
`;

export default Categories;
