import React, { useState } from 'react';
import styled from 'styled-components';

export default function Alert(props) {
    return (
        <Container full={props.full}>
            <Padding>
                <div>
                    <Bold>هشدار: </Bold>
                    <Content>
                    وب اپلیکیشن مدراگ صرفا یک راهنما جهت تجویز دقیق تر داروها برای پزشکان است و مسئولیت تجویز دارو بر عهده پزشک و بر اساس تشخیص بالینی پزشک می‌باشد.<br /> لذا صاحبان وب اپلیکیشن مدراگ هیچ‌گونه مسئولیتی در قبال کاربران این وب اپلیکیشن ندارند
                    </Content>
                </div>
                <button className='btn' onClick={props.close}>
                    موافقت میکنم
                </button>
            </Padding>

        </Container>
    )
}

const Container = styled.div`
    background-color: white;
    width: max-content;
    color: #dc3545;
    border: 2px solid #dc3545;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;

    .btn {
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #231e5b;
        color: #fff;
        font-size: 16px;
        font-family: 'dana';
        padding: 5px 15px;
        margin-top: 20px;
        flex: 1;
        cursor: pointer;
    }

    ${({full}) => full && `width: 100%`}
`;

const Bold = styled.p`
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 4px;
`;

const Content = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 400;
`

const Padding = styled.div`
    padding: 8px 20px;
`;