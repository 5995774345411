import React, { memo, useState } from 'react';
import styled from 'styled-components';

import Arrow from '../../assets/images/arrow.png';
import Modal from '../common/logout';
import { history } from '../../utils/history';

const Header = memo(({ title, inner }) => {
  const [status, setStatus] = useState(false);

  const logOut = (e) => setStatus(true);

  const isLoggenIn = () => !!localStorage.getItem('@token')

  return (
    <Container inner={inner}>
      <div className='column'></div>
      <h1 className='title'>{title}</h1>
      {inner ? (
        <div className='link' onClick={() => history.goBack()}>
          <img src={`${Arrow}`} />
        </div>
      ) : (
          isLoggenIn() ?
            <div style={{ marginTop: '-10px', fontSize: '18px' }} className='link' onClick={logOut}>
              خروج
        </div>
            :
            <div style={{ marginTop: '-10px', fontSize: '18px' }} className='link' onClick={() => {history.push('/login')}}>
              ورود
      </div>
        )}
      {status && <Modal onClose={() => setStatus(false)} />}
    </Container>
  );
});

const Container = styled.div`
  position: ${(props) => (props.inner ? 'fixed' : 'relative')};
  top: ${(props) => (props.inner ? 0 : 'auto')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 5px 10px 0 rgb(206 206 206 / 20%);
  background-color: #fdcb09;
  min-height: ${(props) => (props.inner ? '60px' : '80px')};
  z-index: 100;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -40px;
    background-color: #231e5b;
    width: 110%;
    height: 60px;
    border-radius: 50%;
    transform: translate(-50%, 0);
    z-index: 4;
    display: ${(props) => (props.inner ? 'none' : 'block')};
  }
  .link {
    display: inline-flex;
    flex: 1;
    justify-content: flex-end;
    padding: 0 15px;
  }
  .column {
    flex: 1;
    padding: 0 15px;
  }
  .title {
    position: relative;
    font-size: ${(props) => (props.inner ? '1.3em' : '1.4em')};
    color: #231e5b;
    margin: 0;
    margin-top: ${(props) => (props.inner ? '0' : '-10px')};
    z-index: 10;
    text-align: center;
    flex: 4;
  }
  img {
    height: 15px;
  }
`;

export default Header;
