import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toast';

import Logo from '../assets/images/logo.jpg';
import axios from 'axios';

const toEnglishDigits = (string) => {
  string = typeof string === 'number' ? JSON.stringify(string) : string;
  var e = '۰'.charCodeAt(0);
  string = string.replace(/[۰-۹]/g, function (t) {
    return t.charCodeAt(0) - e;
  });
  e = '٠'.charCodeAt(0);
  string = string.replace(/[٠-٩]/g, function (t) {
    return t.charCodeAt(0) - e;
  });
  string = string.replace(/,(\d+)$/, '.').replace(/٫/, '.');
  return string;
};

const Login = ({ history }) => {
  const [form, setForm] = useState({
    email: '',
    password: '',
    error: '',
  });
  const [isLoading, setLoading] = useState(false);

  const [hasFormSubmited, setFormSubmited] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (value) => value.length > 0;

  const submit = (e) => {
    e.preventDefault();
    setFormSubmited(true);
    if (validateEmail(form.email) && validatePassword(form.password)) {
      setLoading(true);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const params = new URLSearchParams()
      params.append('identifier', toEnglishDigits(form.email))
      params.append('password', form.password)

      let url = `https://api.medrugapp.ir/`;
      // axios
      //   .post(`${url}auth/local`, {
      //     identifier: toEnglishDigits(form.email),
      //     password: form.password,
      //   })

      axios
        .post(`${url}auth/local`, params, config)
        .then((res) => {
          localStorage.setItem('@token', res.data.jwt);
          localStorage.setItem('@userInfo', JSON.stringify(res.data.user));
          localStorage.setItem('@isPaid', JSON.stringify(res.data.user.isPaid));
          return history.push('/');
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            toast.error('نام کاربری یا رمز عبور شما اشتباه است.');
          } else {
            toast.error('در برقراری ارتباط مشکلی به وجود آمده است، مجددا تلاش نمایید.');
          }
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <div className='logo-container'>
        <img className='logo' src={Logo} />
      </div>
      <form onSubmit={submit} noValidate>
        <div className='form-control'>
          <label>ایمیل</label>
          <input type='email' autoCapitalize={false} className={`form-input ${hasFormSubmited && !validateEmail(form.email) ? 'has-error' : ''}`} onChange={(e) => setForm({ ...form, email: e.target.value.trim() })} />
          {hasFormSubmited && !validateEmail(form.email) ? <span className='error'>ایمیل خود را وارد نمایید.</span> : ''}
        </div>
        <div className='form-control'>
          <label>رمز عبور</label>
          <input type='password' autoCapitalize={false} className={`form-input ${hasFormSubmited && !validatePassword(form.password) ? 'has-error' : ''}`} onChange={(e) => setForm({ ...form, password: e.target.value.trim() })} />
          {hasFormSubmited && !validatePassword(form.password) ? <span className='error'>رمز عبور خود را وارد نمایید.</span> : ''}
        </div>
        <button className={`btn`} type='submit'>
          {isLoading ? (
            <div className='lds-ring'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            'ورود'
          )}
        </button>
      </form>
      <Link className='register' to={'register'}>
        اگر حساب کاربری ندارید از اینجا وارد شوید.
      </Link>
      <Link className='register' to={'forgotPassword'}>
        فراموشی رمز عبور؟
      </Link>
      <ToastContainer position={'bottom-center'} delay={5000} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
  .logo-container {
    background-color: #fccb09;
    padding: 10px;
    border-radius: 90px;
    display: block;
    margin-bottom: 30px;
    box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 20%);
    overflow: hidden;
  }
  .logo {
    display: block;
    margin: 0 auto;
    width: 180px;
    @media (max-width: 376px) {
      width: 110px;
    }
  }
  form {
    padding: 30px;
    border-radius: 6px;
    background-color: #fff;
    width: 60%;
    box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 20%);
  }
  .form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 10px;
      display: inline-block;
      color: #231e5b;
    }
    .form-input {
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e2e0e0;
      padding: 0 10px;
      color: #231e5b;
      font-size: 14px;
      box-shadow: none;
      .form-input:focus,
      .form-input:active {
        border-color: #231e5b;
      }
    }
    .error {
      display: inline-block;
      font-size: 13px;
      color: #cc0808;
      margin-top: 8px;
    }
    .has-error {
      border-color: #cc0808;
    }
  }
  .btn {
    border-radius: 4px;
    border: 0;
    box-shadow: 0;
    outline: none;
    background-color: #231e5b;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 25px;
    font-family: 'dana';
    margin: 0 auto;
    margin-top: 20px;
    min-width: 120px;
    display: block;
    transition: opacity 280ms ease-in;
    &:hover {
      opacity: 0.9;
      transition: opacity 280ms ease-in;
    }
    @media (max-width: 376px) {
      font-size: 16px;
    }
  }
  .register {
    color: #fff;
    margin-top: 30px;
    font-size: 18px;
    text-decoration: none;
    @media (max-width: 376px) {
      font-size: 16px;
    }
  }
`;

export default Login;
