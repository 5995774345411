import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Parser } from 'expr-eval';
import { ToastContainer, toast } from 'react-toast';

import Heart from '../../assets/images/heart.png';
import Trash from '../../assets/images/trash.png';
import Alert from './Alert';

const Modal = ({ onClose, selectedItem, withoutfavorite, onUpdate }) => {
  const [weight, setWeight] = useState('');
  const [hasSolved, setHasSolved] = useState(false);
  const [items, setItems] = useState(selectedItem.items);
  let inputRef = useRef(null);

  useEffect(() => {
    if (weight !== '') {
      return onCalculate();
    }
  }, []);

  const toEnglishDigits = (str) => {
    var e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  };

  const onCalculate = () => {
    let variable = inputRef.current ? Number(toEnglishDigits(inputRef.current.value)) : weight;
    setWeight(Number(variable));
    let array = JSON.parse(JSON.stringify(selectedItem));
    localStorage.setItem('weight', variable);
    const parser = new Parser();
    if (array.formula) {
      console.log(array.formula);
      array.formula.forEach((item) => {
        let result = array.items.find((doc) => doc.id === item.id);
        item.formula.forEach((el) => {
          if (result) {
            let secondArray = el.toString().split('-');
            secondArray.forEach((doc) => {
              console.log(doc);
              try {
                let expr = parser.parse(doc);
                result.name = result.name.replace(doc, Number(expr.evaluate({ w: variable })).toFixed(1));
                setItems([...array.items]);
              } catch (err) {
                return doc;
              }
            });
          }
        });
      });
    }
    setHasSolved(true);
  };

  const onFavorite = () => {
    let items = JSON.parse(localStorage.getItem('@favorites'));

    if (items) {
      if(!!items.find(item=>item.id===selectedItem.id)) {
        items = items.filter(item=>item.id!==selectedItem.id);
      }
      else {
        items.push(selectedItem);
      }
      localStorage.setItem('@favorites', JSON.stringify(items));
      return onClose();
    }
    localStorage.setItem('@favorites', JSON.stringify([selectedItem]));
    return onClose();
  };

  const onRemove = (id) => {
    let items = JSON.parse(localStorage.getItem('@favorites'));
    let result = items.filter((item) => item.id !== selectedItem.id);
    localStorage.setItem('@favorites', JSON.stringify(result));
    return onUpdate();
  };

  const isFavorite = () => {
    let items = JSON.parse(localStorage.getItem('@favorites'));
    if(items)
      return !!items.find(item=>item.id===selectedItem.id)
    return false;
  }

  return (
    <OverLay onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <span className='title'>{selectedItem.title}</span>
        {selectedItem.description && (
          <p className='description'>
            <span className='circle'></span>
            {selectedItem.description}
          </p>
        )}
        {selectedItem.tips && (
          <p className='description'>
            <span className='circle'></span>
            {selectedItem.tips}
          </p>
        )}
        {selectedItem.ingredient && (
          <p className='description'>
            <span className='circle'></span>
            {selectedItem.ingredient}
          </p>
        )}
        {selectedItem.formula && selectedItem.formula.length > 0 ? (
          <div className='form-container'>
            <input dir='ltr' ref={inputRef} className='input' type='text' placeholder='وزن خود را وارد نمایید' />
            <button className='btn' onClick={onCalculate}>
              محاسبه مقدار سی‌ سی
            </button>
          </div>
        ) : null}
        {hasSolved || !selectedItem.formula
          ? items.map((item) => {
              return (
                <p key={item.id} className='description'>
                  <span className='circle'></span>
                  {item.name}
                </p>
              );
            })
          : ''}
        <div className='row'>
          <button className={`btn favorite-btn ${withoutfavorite && 'remove-btn'}`} onClick={!withoutfavorite ? onFavorite : onRemove}>
            <img src={!withoutfavorite ? isFavorite() ? Trash : Heart : Trash} />
          </button>
          <button className='btn' onClick={onClose}>
            بستن
          </button>
        </div>
      </Container>
      <ToastContainer position={'bottom-center'} delay={5000} />
    </OverLay>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  display: flex;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  z-index: 40;
  background-color: #fff;
  transition: background-color 480ms ease-in-out;
  transform: translate(0, -50%);
  .close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: #fff;
      }
    }
  }
  .title {
    font-size: 1.4em;
    font-weight: bold;
    color: #616d85;
    margin-bottom: 15px;
    transition: color 480ms ease-in-out;
    text-align: center;
  }
  img {
    max-height: 200px;
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-left: 5px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #ddd;
  }
  .description {
    position: relative;
    font-size: 16px;
    color: #616d85;
    margin: 0 0 10px 0;
    transition: color 480ms ease-in-out;
    .circle {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #231e5b55;
      margin-left: 8px;
    }
  }
  .price {
    color: #222;
    font-size: 14px;
    transition: color 480ms ease-in-out;
    text-align: left;
    width: 100%auto;
    div {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .btn {
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #231e5b;
    color: #fff;
    font-size: 15px;
    font-family: 'dana';
    padding: 5px 15px;
    margin-top: 20px;
    flex: 1;
  }
  .favorite-btn {
    background-color: #fff;
    border: 1px solid #231e5b;
    color: #231e5b;
    flex: 1;
    margin-left: 15px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      height: 18px;
      margin: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }
  .remove-btn {
    border: 1px solid #bf0000;
  }
  .form-container {
    text-align: center;
    margin-bottom: 25px;
    .input {
      font-family: 'dana';
      margin: 5px 20px;
      min-height: 35px;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 15px;
      color: #231e5b;
      box-shadow: unset;
      direction: ltr;
      text-align: left;
    }
    .input::placeholder {
      text-align: right;
    }
  }
`;

const OverLay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 350;
`;

export default Modal;
