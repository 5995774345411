import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Components
import Header from '../components/common/header';
import Categories from '../components/common/categories';
import Tab from '../components/common/tab';

// Utils
import axios from '../utils/axios';

const Home = () => {
  const [isLoading, setLoading] = useState(false);

  async function fetchData() {
    try {
      let user = JSON.parse(localStorage.getItem('@userInfo'));
      if (user) {
        let result = await axios.get(`/transactions?status=100&user.id=${user.id}`);
        result.data.forEach((item) => {
          let expireDate = moment(item.createdAt).add(1, 'year');
          if (moment().isBefore(moment(expireDate))) {
            localStorage.setItem('@isPaid', true);
            localStorage.setItem('@transaction', item);
            user.isPaid = true;
          }
          return item;
        });
      }
      // setLoading(false);
      return user;
    } catch (e) {
      // setLoading(false);
      let result = JSON.parse(localStorage.getItem('@isPaid')),
        transaction = JSON.parse(localStorage.getItem('@transaction'));
      if (result && transaction) {
        let expireDate = moment(transaction.createdAt).add(1, 'year');
        if (moment().isAfter(moment(expireDate))) {
          localStorage.setItem('@isPaid', false);
        }
      }
    }
  }

  useEffect(() => {
    // async function fetchData() {
    //   try {
    //     let user = JSON.parse(localStorage.getItem('@userInfo'));
    //     if (user) {
    //       let result = await axios.get(`/transactions?status=100&user.id=${user.id}`);
    //       result.data.forEach((item) => {
    //         let expireDate = moment(item.createdAt).add(1, 'year');
    //         if (moment().isBefore(moment(expireDate))) {
    //           localStorage.setItem('@isPaid', true);
    //           localStorage.setItem('@transaction', item);
    //           user.isPaid = true;
    //         }
    //         return item;
    //       });
    //     }
    //     setLoading(false);
    //     return user;
    //   } catch (e) {
    //     let result = JSON.parse(localStorage.getItem('@isPaid')),
    //       transaction = JSON.parse(localStorage.getItem('@transaction'));
    //     if (result && transaction) {
    //       let expireDate = moment(transaction.createdAt).add(1, 'year');
    //       if (moment().isAfter(moment(expireDate))) {
    //         localStorage.setItem('@isPaid', false);
    //       }
    //     }
    //     setLoading(false);
    //   }
    // }
    fetchData();
  }, []);

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </LoadingContainer>
      )}
      <Header title={'صفحه اصلی'} />
      <Categories />
      <Tab />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #231e5b;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.25);
`;

const ReloadButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: #231e5b;
  border-radius: 50%;

`

export default Home;
